<template>
  <a-row :gutter="25">
    <!--{{ "dataForm "  +  JSON.stringify(dataForm.dataTableInfo) }}-->
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="showLoader">
      <sdCards>
        <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
      </sdCards>
    </a-col>
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="!showLoader">
      <a-row :gutter="25" class="ml-2" v-if="showMessageRequired">
        <span class="field-title" style="color: red;">Formulario dinámico requerido</span
        ><br />
      </a-row>
      <a-row :gutter="25" class="ml-2">
        <span class="event--title">{{ event.title }}</span
        ><br />
      </a-row>
      <a-row :gutter="25" class="ml-2 mt-2">
        <a-col :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
          <span class="field-title">Fecha de inicio del evento</span><br />
          <p class="content">
            {{
              event.event_start_date ? `${formatDate(event.event_start_date)}, ${removeSeconds(event.event_start_hour)} hrs.` : 'No definida'
            }}
          </p>
        </a-col>
        <a-col :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
          <span class="field-title">Fecha de finalización del evento</span><br />
          <p class="content">
            {{
              event.event_end_date ? `${formatDate(event.event_end_date)}, ${removeSeconds(event.event_end_hour)} hrs.` : 'No definida'
            }}
          </p>
        </a-col>
      </a-row>

      <tenplate v-if="!collapsed">
        <a-row :gutter="25" class="ml-2 mt-2">
          <a-col class="card-fields" :xxl="12" :lg="12" :md="24" :xs="24">
            <span class="field-title">Sedes</span>
            <a-col
              v-for="(sede, index) in getSedesData(event)"
              :key="sede"
              :xxl="24"
              :lg="24"
              :md="24"
              :xs="24"
              class="card-fields"
            >
              <span class="title-normal">#{{ index + 1 }}</span>
              <br />
              <div v-for="element in sede" :key="element">
                <div v-if="element.value != null">
                  <span class="">{{ element.label }}</span
                  ><br />
                  <span class="field-text-value">{{ element.value }}</span>
                </div>
              </div>
            </a-col>
          </a-col>
          <a-col :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
            <span class="field-title">¿A quién va dirigido el evento?</span><br />
                <div class="content">
                  {{ aimedAt() }}
                </div>
          </a-col>
        </a-row>
        <br />

        <a-row :gutter="25" v-if="dynamicFormEvent != null && event.activities && !showRegisterParticipant && !showRegisterAssistant">
          <a-col :xxl="24" :lg="24" :md="24" :xs="24">
            <button class="button-normal" @click="showDynamicForm = !showDynamicForm">
              <span class="title-normal" v-if="!showDynamicForm">Contestar formulario</span>
              <span class="title-normal" v-else>Ocultar formulario</span>
            </button>
          </a-col>
        </a-row>
        <a-row :gutter='25' v-if='showRegisterParticipant || showRegisterAssistant'>
          <div class='form__buttons'>

            <button class='btn-fill-add' @click='handleRegisterClick(true)'
                    v-if='showRegisterAssistant'>
              <span class='title-normal'> Registrar asistentes </span>
            </button>
            <button class='btn-fill-add' @click='handleRegisterClick(false)'
                    v-if='showRegisterParticipant'>
              <span class='title-normal'> Registrar participantes </span>
            </button>

          </div>
        </a-row>
      </tenplate>
      <br />
      <template v-if="showDynamicForm && dynamicFormEvent != null && !collapsed ">
      <a-row :gutter='25' >
        <a-col :xxl='24' :lg='24' :xs='24' class='mb-2' v-if='dynamicFormEvent?.sections?.length > 1'>
          <span class='title-normal'>{{ `Sección ${tabValue +1}` }}</span>
        </a-col>
        <a-col :xxl='11' :lg='11' :xs='24' v-for='(question, idx) in dynamicFormEvent.sections[tabValue].questions'
                :key='question.id'>
            <question-render
              :modelValue='answers[question.id].content'
              :question='question'
              :errors='errors'
              @update:modelValue='handleUpdateValue(answers[question.id], $event ,question) '
              @update:cctmodelValue="searchCCT($event, question, tabValue, idx)"
            />
        </a-col>
      </a-row>
      <a-row :gutter='25'>
        <div class='form__buttons' v-if='dynamicFormEvent?.sections?.length > 1' >

          <button
            class='btn-fill-add'
            @click='handleValidateTypeQuestion(dynamicFormEvent.sections[tabValue].questions, tabValue, false )'
            v-if="tabValue > 0">
            <span class='title-normal'> Ir a la sección anterior </span>
          </button>
          <button
            class='btn-fill-add'
            @click='handleValidateTypeQuestion(dynamicFormEvent.sections[tabValue].questions, tabValue, true )'
            v-if="tabValue < dynamicFormEvent?.sections?.length - 1 && !endForm">
            <span class='title-normal'> Ir a la siguiente sección </span>
          </button>

        </div>
      </a-row>
      </template>

    </a-col>
  </a-row>
  <div class="w-100 mt-2" style="background-color: #CCCCCC; height: 1px" />
  <a-row :gutter="25" class="ml-2 mt-2 justify-content-center">
    <sdFeatherIcons
      class="btn-collapse"
      :type="collapsed ? 'chevron-down' : 'chevron-up'"
      size="21"
      @click="collapsed = !collapsed"
    />
  </a-row>

</template>

<script>
import { defineAsyncComponent } from 'vue';
import { formatDate, validateDynamicFormSection } from '@/utility/utility';
import QuestionRender from '@/view/home/overview/QuestionRender';
import {getQuestionLevel3CCT} from '@/services/home';
import { useRouter } from 'vue-router';
import {removeSeconds} from '@/utility/utility';

const DynamicFormComponent = defineAsyncComponent(() =>
  import('../../validation-events/overview/details/dynamicForm.vue'),
);

const SubEvent = {
  name: 'ValidationSubEventsDetailsComponent',
  components: {
    DynamicFormComponent,
    QuestionRender
  },
  emits: ['validateForm'],
  props: {
    showRegisterParticipant: false,
    showRegisterAssistant: false,
    event: null,
    dynamicFormEvent: null,
    indexSubevent: 0,
    getValidation: false,
    parentID: null,
    hideLink: false
  },
  watch: {
    getValidation: function () {
      this.validateSectionAndEmit();
    },
  },
  mounted() {
    this.setDinamicData()
  },
  data() {
    return {
      showLoader: false,
      base_url: process.env.VUE_APP_BASE_URL,
      //dynamicForm: this.dynamicForm,
      collapsed: true,
      showDynamicForm: false,
      errors: {},
      answers: {},
      timer: null,
      dynamicForm: null,
      tabValue: 0,
      sendFormLoading: false,
      router: useRouter(),
      sectionsRoute: [0],
      endForm: false,
      showMessageRequired: false,
    };
  },
  methods: {
    handleUpdateValue(value, newValue, question){
      value.content = newValue
      if(value.type === 'multipleChoice' || value.type === "dropDown" && question.goto){
        const option = question.options.find(o=>o.id === value.content);
        if(option && option.goto === "finish"){
          this.endForm = true;
        }else{
          this.endForm = false;
        }
      }
    },
    handleValidateTypeQuestion(questions, sectionIndex, next){


      const steps = next ? 1 : -1;
      //no validate current section, just go back
      if (!next) {
        this.sectionsRoute.pop();
        this.handleChangeSection(
          this.sectionsRoute[this.sectionsRoute.length - 1],
          steps
        )
        return
      }

      //if current section is not valid not continue
      if (!this.validateSection()){
        return;
      }

      //search last question type multipleChoice or dropDown.
      let question = null
      for (let i = questions.length - 1; i >= 0; i--) {
        if (questions[i].type === 'multipleChoice' || questions[i].type === 'dropDown') {
          question = questions[i];
          break;
        }
      }

      //get item ID selected in this question.
      const optionIDSelect = this.answers[question?.id]?.content

      //if question is defined, this question is  multipleChoice or dropDown
      //if this question.goto = true allow "dynamic sections"
      if (question && question.goto) {
        //find de option selected to compare the "goto" property
        const option = question.options.find(item => item.id === optionIDSelect)
        //Goto next section
        if (option.goto === 'next') {
          this.handleChangeSection( sectionIndex + steps, steps)
        } else if (option.goto === 'finish') {
          //goto last section
          this.handleChangeSection( this.dynamicForm.sections.length - 1, 1)
        } else {
          //go to a specific section with its id
          const indexToGo = this.dynamicForm.sections.indexOf(this.dynamicForm.sections.find(item => item.id === option.goto))
          if(indexToGo != this.tabValue){
            this.handleChangeSection( indexToGo, steps)
          }
        }

      } else {
        //this question is not multipleChoice or dropdown
        //this question is  multipleChoice or dropDown  but not allow "dynamic sections"
        this.handleChangeSection( sectionIndex + steps, steps)
      }
    },
    async handleChangeSection(nextSection, value){
      if (value == 1) {
        this.tabValue = nextSection
        this.sectionsRoute.push(nextSection)
      } else {
        this.tabValue = nextSection
      }

    },
    handleRegisterClick(assistants){
      const url = `/home/registers/${assistants ? "assistants" : "participants"}/${this.parentID}`;
      this.router.push({path: url, query:{subEvent:this.event.id }})
    },
    getSedesData(event) {
      let fields = [];
      event.headquarters.forEach(element => {
        let sede = '';
        let type = '';
        if (element.type == 'face-to-face') {
          type = 'Presencial';
          sede = `${element.name}, ${element.street}, ${
            element.suburb != null ? element.suburb+',' : element.other_suburb ? element.other_suburb+',' : ''
          } ${element.municipality ? element.municipality+',' : ''} ${element.postal_code ? element.postal_code : ''}`;
        } else if (element.type == 'virtual') {
          type = 'Virtual';
          sede = `${element.virtual_platform}${this.hideLink ? '' :  ', '+element.link}`;
        } else {
          type = 'Ambas (vitual y presencial)';
          sede = `${element.virtual_platform}${this.hideLink ? '' : ', '+element.link}. ${element.name}, ${element.street}, ${
            element.suburb != null ? element.suburb+',' : element.other_suburb ? element.other_suburb+',' : ''}
            ${element.municipality ? element.municipality+',' : ''} ${element.postal_code ? element.postal_code : ''}`;
        }
          fields.push([
            {
              label: type,
              value: sede,
            },
          ]);


      });

      return fields;
    },
    async searchCCT(event, question, sectionIdx, questionIdx){
      if (this.timer){
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        //SendRequest
        if (!event) {
          //props.setCCTLoading(false);
          return;
        }
        const response = await getQuestionLevel3CCT(event)
        if (response.success && response?.data?.length) {
          const data =  response?.data ? response.data[0] || {} : {}

          for (let i = 0; i < question.cct.length; i++) {
            const fields = question.cct[i].split('.'); //Split fields by '.'
            let value = data;
            fields.forEach(field => {
              value = value[field];
            })
            let cctFillQuestion = this.dynamicForm.sections[sectionIdx].questions[questionIdx + i + 1];
            this.answers[cctFillQuestion.id].content = value;
          }
          this.errors[question.id] = '';
        } else {
          this.errors[question.id] = 'La clave ingresada no es correcta';
        }
      }, 2000);

      this.answers[question.id].content = event

    },
    setDinamicData() {
      if(this.dynamicFormEvent != null){
        this.dynamicForm = this.dynamicFormEvent;

        this.dynamicForm.sections.forEach(section => {
          section.questions.forEach(q => {
            this.answers[q.id] = {
              type: q.type,
              content: q.type == 'file' ? [] : '',
            };
          });
        });

      }
    },

    async validateSection() {
      this.errors = await validateDynamicFormSection(this.dynamicFormEvent.sections[this.tabValue], this.answers);
      for (let error in this.errors) {
        if (this.errors[error]){
          return false;
        }
      }
      return true;
    },
    async validateSectionAndEmit(){
      if(!this.event.activities){
        this.showMessageRequired = false;
        this.$emit('validateForm', { isValid: true, eventID: this.event.id, answers: this.answers, title: this.event.title });
        return;
      }
        if (await this.validateSection()){
          this.showMessageRequired = false;
          this.$emit('validateForm', { isValid: true, eventID: this.event.id, answers: this.answers, title: this.event.title })
        }else{
          this.showMessageRequired = true;
          this.$emit('validateForm', { isValid: false, eventID: this.event.id, answers: null, title: this.event.title })
        }

    }
  },

  async setup(props) {

    const aimedAt = () => {
      const educational = props.event.edu_can_register ? 'Estructura educativa' : '';
      const administrative = props.event.admin_can_register ? 'Personal en centro de trabajo administrativo' : '';
      const student = props.event.student_can_register ? 'Estudiantes' : '';
      const citizen = props.event.citizen_can_register ? 'Ciudadanos' : '';
      // const aimedAt = educational + ' ' + administrative + ' ' + student + ' ' + citizen;
      const aimedAt = [educational, administrative, student, citizen];
      return aimedAt.filter(val => !!val).join(' y ');
      // return aimedAt.trim().replace(/\s+/g, ' y ');
    };

    return { formatDate, aimedAt, removeSeconds };
  },
};
export default SubEvent;
</script>
<style scoped>
.button-normal {
  background: #3FA7E1;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 8px 15px 8px 15px;
  cursor: pointer;
}

.card-title {
  padding: 1rem;
  border-left: 0.5rem solid #3fa7e1;
  background: #f8f8f8;
  border-top: 2px solid #e8e5e5;
  font-weight: bold !important;
}

.card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}

.card-fields {
  padding: 0 !important;
}

.field-title {
  font-weight: 600;
  font-family: 'Avenir Heavy';
  font-size: 14px;
}

.field-text-value {
  color: #7b868c;
  font-family: 'Avenir Roman';
  font-size: 14px;
}

.text-file {
  color: #e8394d;
}

.event--title {
  font-family: 'Nutmeg Bold';
  font-size: 18px;
  color: #404040;
}
.btn-collapse {
  background-color: rgba(63, 167, 225, 0.15);
  color: #3fa7e1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-collapse:hover {
  cursor: pointer;
}
.form__buttons{
  padding: 32px 21px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.btn-fill-add{
  border: 1px solid #9ED2EF;
  color: #ffffff;
  padding: 5px 15px 5px 15px;
  background: #3FA7E1;
  border-radius: 24px;
}

button:hover{
  cursor: pointer;
}


</style>
